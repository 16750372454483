const bubbles = document.querySelectorAll('.bubbles');
const activeBubbleClassName = 'is-active';

bubbles.forEach(bubblesContainer => {
  const bubblesBackdrop = bubblesContainer.querySelector('.bubbles-backdrop');
  const bubbleItems = bubblesContainer.querySelectorAll('.bubble-item');
  let currentBubbleItem = null;
  
  bubbleItems.forEach((bubbleItem, index) => {
    bubbleItem.addEventListener('click', event => selectBubble(bubbleItem));
  });
  
  function selectBubble(bubbleItem) {
    deselectCurrentBubble();

    // When clicking the same bubble again, deselect
    if(bubbleItem.dataset.isActive) {
      delete bubbleItem.dataset.isActive;
      console.log('deselect');
      return;
    } else {
      bubbleItem.dataset.isActive = true;
    }

    // Select current bubble
    bubbleItem.classList.add(activeBubbleClassName);
    currentBubbleItem = bubbleItem;

    const bubbleText = bubbleItem.querySelector('.bubble-text');
    bubbleText.style.left = `50%`;

    const bubbleTextContent = bubbleText.querySelector('.bubble-text-content');
    const bubbleTextPos = bubbleText.getBoundingClientRect();
    bubblesBackdrop.classList.add('is-active');
  
    // Smooth scroll bubble image to top
    bubbleItem.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  
    // Set text height to not overflow window
    if(bubbleTextContent.clientHeight > window.innerHeight - 352) {
      bubbleText.style.height = `calc(${window.innerHeight}px - 22rem)`;
    } else {
      bubbleText.style.height = 'unset';
    }

    // Set text position not to go off canvas
    if(bubbleTextPos.left < 0) {
      bubbleText.style.left = `calc(50% + ${Math.round(bubbleTextPos.left * -1)}px + 1rem)`;
    } else if(bubbleTextPos.right > window.innerWidth) {
      bubbleText.style.left = '0';
    }
  }
  
  function deselectCurrentBubble(removeActive) {
    document.querySelectorAll('.bubble-item.is-active').forEach(bubbleItem => {
      bubbleItem.classList.remove(activeBubbleClassName);
      if(removeActive) {
        delete bubbleItem.dataset.isActive;
      }
    });
    currentBubbleItem = null;
    bubblesBackdrop.classList.remove('is-active');
  }

  bubblesBackdrop.addEventListener('click', () => {
    bubblesBackdrop.classList.remove('is-active');
    deselectCurrentBubble(true);
  });
})